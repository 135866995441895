<template>
  <div class="product-props">
    <div class="product-props__container">
      <template v-if="data.recommend_products && data.recommend_products.length">
        <div class="product-props__right" v-if="!isMobile">
          <h3 class="product-props__title">С товаром рекомендуют</h3>
          <ul class="product-props__products">
            <li v-for="(item, i) in data.recommend_products.slice(0, 6)" :key="i">
              <ProductSmallCardComponent :data="item" />
            </li>
          </ul>
        </div>
        <SliderComponent
          title="С товаром рекомендуют"
          :slider-options="sliderOptions"
          :items="data.recommend_products"
          v-slot="{ item }"
          v-else
        >
          <ProductSmallCardComponent :data="item" />
        </SliderComponent>
      </template>
    </div>
  </div>
</template>

<script>
import ProductSmallCardComponent from "components/product/ProductSmallCardComponent.vue";
import SliderComponent from "components/SliderComponent.vue";

export default {
  name: "ProductPropsComponent",
  props: {
    data: Object,
  },
  data() {
    return {
      isMobile: false,
      sliderOptions: {
        slidesPerView: 1.5,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
        breakpoints: {
          460: {
            slidesPerView: 1.8,
            spaceBetween: 15,
          },
          540: {
            slidesPerView: 2.2,
            spaceBetween: 15,
          },
          740: {
            slidesPerView: 2.9,
            spaceBetween: 15,
          },
          860: {
            slidesPerView: 3.5,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 860;
  },
  components: { SliderComponent, ProductSmallCardComponent },
};
</script>

<style lang="stylus">
.product-props {
  display flex
  align-items flex-start
  justify-content center
  padding 0 15px

  &__container {
    max-width var(--main_width)
    width 100%
    display grid
    grid-template-columns 1fr
    align-items start
    grid-gap 32px
    +below(1024px) {
      grid-template-columns 1fr
    }

    .slider {
      padding 0

      &__title {
        font-size: 1.5em;
        line-height: 32px;
        font-weight normal !important
      }
    }
  }

  &__left {
    display grid
    align-items start
  }

  &__right {
    display grid
    grid-gap 24px
  }

  &__article {
    display grid
    grid-gap 8px
    align-items start

    &:not(:last-of-type) {
      margin-bottom 24px
    }
  }

  &__title {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0
  }

  &__description {
    white-space: pre-line;
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
    margin 0
  }

  &__action {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--link);
  }

  &__list {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--gray-600);
    display grid
    margin-bottom 8px

    li {
      border-bottom: 1px solid var(--blue-500);
      padding 4px 0
      display flex
      justify-content space-between
      align-items center
    }
  }

  &__products {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 4px;
		+below(1100px) {
			grid-template-columns repeat(3, 1fr)
		}
  }
}
</style>
