<template>
  <div class="product-page page">
    <BreadcrumbsComponent @back.prevent :links="links" class="product-page__mb-32" />
    <div class="product-page__container product-page__mb-64">
      <ProductComponent :data="data" />
    </div>
    <ProductPropsComponent :data="data" class="product-page__mb-64" />
    <SliderComponent
      title="Похожие товары"
      :items="data.similar_products"
      :slider-options="sliderOptions"
      v-slot="{ item }"
      class="product-page__mb-48"
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
    <ReviewsComponent
      id="reviews"
      class="product-page__mb-64"
      :reviews="data.reviews"
      :data="data"
      reviewable_type="App\Models\ProductItem"
    />
    <SliderComponent
      title="Недавно просмотренные"
      :items="recently_viewed.filter((item) => item.id !== parseInt($route.params.id))"
      :slider-options="sliderOptions"
      v-slot="{ item }"
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
  </div>
</template>

<script>
import ProductComponent from "components/product/ProductComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ProductPropsComponent from "views/product/components/ProductPropsComponent.vue";
import ReviewsComponent from "components/reviews/ReviewsComponent.vue";
import PRODUCT_PAGE from "@/graphql/pages/ProductPage.graphql";

export default {
  name: "ProductPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: PRODUCT_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.state.product_page.product = data.product;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      });
  },
  data() {
    return {
      recently_viewed: [],
      sliderOptions: {
        slidesPerView: 1.5,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
        watchSlidesProgress: true,
        breakpoints: {
          460: {
            slidesPerView: 1.8,
            spaceBetween: 15,
          },
          540: {
            slidesPerView: 2.2,
            spaceBetween: 15,
          },
          740: {
            slidesPerView: 2.9,
            spaceBetween: 15,
          },
          860: {
            slidesPerView: 3.5,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  mounted() {
    // переход к якорю
    if (this.$route.hash) {
      document.querySelector(this.$route.hash).scrollIntoView({ block: "start", behavior: "smooth" });
    }
    // недавно просмотренные
    if (localStorage.viewed) {
      let recentlyViewed = JSON.parse(localStorage.viewed);
      if (!recentlyViewed.find((item) => item.id === parseInt(this.$route.params.id))) {
        recentlyViewed.unshift(this.data);
        localStorage.viewed = JSON.stringify(recentlyViewed);
      }
      this.recently_viewed = recentlyViewed;
    } else {
      localStorage.viewed = JSON.stringify([this.data]);
    }
  },
  computed: {
    data() {
      return this.$store.state.product_page.product;
    },
    links() {
      let links = [];
      if (this.data.category) {
        let category = this.data.category;
        while (category) {
          links.unshift({
            title: category.title,
            route: {
              name: "catalog",
              params: { id: category.id },
            },
          });
          category = category.parent;
        }
      }
      links.push({ title: this.data.title });
      return links;
    },
  },
  components: {
    ReviewsComponent,
    ProductPropsComponent,
    BreadcrumbsComponent,
    ProductCardComponent,
    SliderComponent,
    ProductComponent,
  },
  metaInfo() {
    let image = this.$options.filters.item_img(this.data);
    let imagePath = image ? this.$store.state._env.MEDIA_ENDPOINT + image.path : null;
    return {
      title: this.data.meta_title || this.data.title,
      description: this.data.meta_description || this.data.description,
      keywords: this.data.meta_keywords,
      meta: [
        {
          vmid: "og:title",
          name: "og:title",
          content: this.data.meta_title || this.data.title,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: this.data.meta_description || this.data.description,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: imagePath,
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.product-page {
  display grid
  justify-content: center;
  padding 32px 0 48px
  +below(860px) {
    padding 15px 0
  }

  &__container {
    display flex
    align-items center
    justify-content center
    padding 0 15px
  }

  &__mb-32 {
    margin-bottom 32px
    +below(860px) {
      margin-bottom 16px
    }
  }

  &__mb-48 {
    margin-bottom 48px
    +below(860px) {
      margin-bottom 24px
    }
  }

  &__mb-64 {
    margin-bottom 64px
    +below(860px) {
      margin-bottom 32px
    }
  }
}
</style>
